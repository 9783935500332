<template>
<div class="flex flex-col" >
    <h2 class="text-red text-md">Affiliates</h2>

  <br>
    <h3 class="font-extrabold border-bottom">Campaigns</h3>
    <ul class="ml-4">
        <li v-for="(campaign,i) in affiliateInfo.referrer_campaigns" :key="i">
<!--            <ul>-->
<!--                            <b>{{campaign[i]}}</b>-->
<!--                            <li class="ml-4 inline"><b>Visits:</b> {{campaign.visits}}</li>-->
<!--                <li class="ml-4 inline"><b>converted:</b> {{campaign.converted}}</li>-->
<!--            </ul>-->
          {{i}}{{campaign}}
        </li>
    </ul>
    <br>
    <h3 class="font-extrabold border-bottom">Payouts</h3>
    <div class="col-12 payouts-table">
      <div class="col-12" style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr">
        <th>ID</th>
        <th>Status</th>
        <th>Transactions</th>
        <th>Payout Amount</th>
      </div>
      <div v-for="(payout, i) in affiliateInfo.payouts" class="col-12" style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr">
        <td>{{payout.id}}</td>
        <td>{{payout.status}}</td>
        <td>{{payout.transactions}}</td>
        <td>${{payout.payout_amount}}</td>

        <br>
        <div style="grid-column:span 4;display:flex;flex-direction:column;padding:0px 25px;">
          <div style="grid-column:span 4;display:flex;flex-direction:column;text-decoration:underline">Transactions</div>
          <div style="grid-column:span 4;display:flex;flex-direction:column">
            <div style="display:grid;grid-template-columns:1fr 1fr 1fr 1fr">
              <th>Payout ID</th>
              <th>Amount</th>
              <th>Transaction ID</th>
              <th>Date</th>
            </div>
          </div>
          <div v-for="(transaction, j) in affiliateInfo[i].payout_transactions" style="grid-column:span 4;display:flex;flex-direction:column">
            <div style="display:grid;grid-template-columns:1fr 1fr 1fr 1fr">
              <td>{{transaction.payout_id}}</td>
              <td>${{transaction.amount_commission}}</td>
              <td>{{transaction.transaction_id}}</td>
              <td>{{transaction.created_at.substring(0,10)}}</td>
            </div>
          </div>
          <br>
          <hr>
        </div>
      </div>
    </div>

  <br>
  <h3 class="font-extrabold border-bottom">Pending Transactions</h3>
  <table class="col-12 pending-transactions-table">
    <tr>
      <th>Referral ID</th>
      <th>Amount</th>
      <th>Transaction ID</th>
      <th>Subscription UID</th>
      <th>Date</th>
    </tr>

    <tr v-for="(transaction, i) in affiliateInfo.pending_transactions">
      <td>{{transaction.referral_id}}</td>
      <td>${{transaction.amount_commission}}</td>
      <td>{{transaction.transaction_id}}</td>
      <td>{{transaction.subscription_uid}}</td>
      <td>{{transaction.created_at.substring(0,10)}}</td>
    </tr>
  </table>
</div>
</template>

<script>
import Axios from 'axios'
import Table from "@/components/dashboard/Table.vue";
export default {
    name: 'Affiliates',
  components: {Table},
    data: () => ({
        affiliateInfo: "",
    }),
    computed: {
    },
    props: ['model'],
    methods: {
        GetAffiliates() {
            let that = this;
            this.$requests.post('/customer/getAffiliateInfo',{
                customer: this.model.id,
            },
            {
                success: (re) => {
                  console.log(re.data.item)
                    that.affiliateInfo = re.data.item;
                    return true
                },
            });
        },
    },
    watch: {
        model( newModel )
        {
            if ( newModel.id )
                this.GetAffiliates();
        },
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
        
        this.GetAffiliates();
    },
}
</script>
<style scoped>
.payouts-table th{
  text-align: left;
}
.pending-transactions-table th{
  text-align:left;
}
.border-bottom{
  padding-bottom:5px;
  border-bottom:1px solid black;
}
</style>
