<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Edit Customer
        </h1>
        <p>
          <strong>Customer Since:</strong> {{showCreatedAt()}}
          <br>
          <strong>Last Updated:</strong> {{showUpdatedAt()}}
        </p>
        <SlideoutTabs default_width="half" :model="{id:item.id,type:'Customer'}" :contents="tab_content"  orientation="top-0 bottom-0 right-0" position="fixed">
        </SlideoutTabs>
        <div class="" v-if="item">
            <FormTemplate @response="formResponse" button="Save" method="post" action="/customer" :formdata="formData">
                <ButtonTemplate :on_click="LoginAsCustomer" btn_style="light" class="my-6" type="button">
                   Login As Customer
                </ButtonTemplate>
                <TextInput type="text" label="First Name" v-model="item.first_name" :required="false" ></TextInput>
                <TextInput type="text" label="Last Name" v-model="item.last_name" :required="false" ></TextInput>
                <TextInput type="text" label="Username" v-model="item.username" :required="false" ></TextInput>
                <TextInput type="text" label="Phone Number" v-model="item.phone" :required="false" ></TextInput>
                <TextInput type="email" label="Email" v-model="item.email" :required="false" ></TextInput>
                <div class="text-center">Update password:</div><Checkbox label="Update Password" v-model="item.update_password" :required="false" ></Checkbox>
                <TextInput v-if="item.update_password"  type="text" label="New Password" v-model="item.new_password" :required="false" ></TextInput>
                <SearchSelectInput @item:clicked="AgencyClick" @item:removed="accountRemoved" @item:selected="accountSelected" :params="accountSearchParams" :multiple="true" label="Agencies" v-model="item.accounts" :optiondisplay="accountDisplay" :hideSelected="true"></SearchSelectInput>
                <div class="border border-yellow font-bold flex flex-col">
                    <div class="flex justify-around items-center" v-for="(account_item ,i) in item.accounts" :key="i" >
                        <div class="mx-4">
                            <router-link class="text-red hover:text-yellow hover:underline" :to="{path: '/agencies/'+account_item.id}">
                                {{accountDisplay(account_item)}}
                            </router-link>
                        </div>
                        <SelectInput class="flex-1" label="Team" v-model="item.accounts_teams[account_item.id]" :options="[{label:'Executive',value:'1'},{label:'Development',value:'2'},{label:'Design',value:'3'}, {label:'Customer',value:'4'}]" :required="false" ></SelectInput>
                        <SelectInput class="flex-1" label="Role" v-model="item.accounts_roles[account_item.id]" :options="[{label:'Owner (Legacy! Do not use. Use Executive Manager instead.)',value:'1'},{label:'Manager',value:'2'},{label:'Standard',value:'3'}, {label:'Client',value:'4'}]" :required="false" ></SelectInput>
                        <button title="Remove Item" type="button" @click="RemoveAccountItem(i)" class="flex-1 ml-5 text-red font-bold">
                            X
                        </button>
                    </div>
                </div>

            </FormTemplate> 

            {{item.affiliates}}
        </div>


    </div>

</template>

<script>
import View from '../../../mixins/View'
import Notes from '../../../components/dashboard/Notes'
import Affiliates from '../../../components/dashboard/Affiliates'
export default {
    name: 'Customer',
    mixins: [View],
    data: () => ({
        accountSearchParams: {
            'model': 'Account',
            'fields': ['id','name','email','phone'],
            'action': '/search/',
        },
        parameter: 'customer_id',
        action: '/customer/',
        tab_content: {
            'notes': {
                label: 'Notes',
                icon: '/assets/notes.png',
                component: Notes,
                top: '200px',
            },
            'affiliates': {
                label: 'Affiliates',
                icon: '/assets/customers.png',
                component: Affiliates,
                top: '300px',
            }
        }
    }),
    computed: {
        formData() {
            return {
                'customer':this.item,
            }
        },
    },
    props: [],
    methods: {
        accountDisplay(item) {
            return 'ID: ' + item.id + ' Name: ' + item.name;
        },
        accountSelected(evt) {
            let index = this.item.accounts.findIndex((account)=>{return account.id == evt.item.id})
            if(index < 0) this.item.accounts.push(evt.item);
        },
        accountRemoved(evt) {
            this.item.accounts.splice(evt.index,1);
        },
        RemoveAccountItem(index) {
            this.item.accounts.splice(index,1)
        },
        AgencyClick(evt) {
            this.$router.push({path: `/agencies/${evt.item.id}`})
        },
        LoginAsCustomer() {
            this.$requests.post('/customer/login',{
                customer: this.item,
            },
            {
                success: this.OpenNewWindow,
            })
        },
        OpenNewWindow(data) {
            let url = this.$store.state.data.dashboard + '/signin/?oada_token='+data.data.token
            this.$store.commit('modal/setContent',{
                title: 'Log in using this link',
                content: url,
                open: true,
            })
            return true;
        },
        showCreatedAt(){
          let createdDate = new Date(this.item.created_at);

          return createdDate.toLocaleDateString();
        },
        showUpdatedAt(){
          let createdDate = new Date(this.item.updated_at);

          return createdDate.toLocaleDateString();
        }
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
