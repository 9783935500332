<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Create Customer
        </h1>
        <div class="" >
            <FormTemplate @response="formResponse" button="Create" method="post" action="/customer/create" :formdata="formData">
                <TextInput type="text" label="First Name" v-model="item.first_name" :required="false" ></TextInput>
                <TextInput type="text" label="Last Name" v-model="item.last_name" :required="false" ></TextInput>
                <TextInput type="text" label="Username" v-model="item.username" :required="false" ></TextInput>
                <TextInput type="text" label="Phone Number" v-model="item.phone" :required="false" ></TextInput>
                <TextInput type="text" label="Email" v-model="item.email" :required="false" ></TextInput>
                <TextInput type="text" label="Password" v-model="item.password" :required="false" ></TextInput>
                <ButtonTemplate :on_click="generatePw" btn_style="light" class="mb-6" type="button">
                    Generate Password
                </ButtonTemplate>
                <Checkbox label="Create a new agency" v-model="item.create_account" :required="false" ></Checkbox>
                <SearchSelectInput v-if="!item.create_account" @item:removed="accountRemoved" @item:selected="accountSelected" :params="accountSearchParams" :multiple="true" label="Agencies" v-model="item.accounts" :optiondisplay="accountDisplay"></SearchSelectInput>
                <div v-if="!item.create_account">
                    <SelectInput v-for="(account_item ,i) in item.accounts" :key="i" :label="accountDisplay(account_item)+' Role '" v-model="item.accounts_roles[account_item.id]" :options="[{label:'Owner',value:'1'},{label:'Manager',value:'2'},{label:'Standard',value:'3'}, {label:'Client',value:'4'}]" :required="false" ></SelectInput>
                    <SelectInput v-for="(account_item ,i) in item.accounts" :key="i" :label="accountDisplay(account_item)+' Team '" v-model="item.accounts_teams[account_item.id]" :options="[{label:'Executive',value:'1'},{label:'Development',value:'2'},{label:'Design',value:'3'}, {label:'Customer',value:'4'}]" :required="false" ></SelectInput>
                </div>
                <div v-if="item.create_account">
                    <TextInput type="text" label="Agency Name" v-model="item.account.name" :required="false" ></TextInput>
                    <TextInput type="text" label="Agency Email" v-model="item.account.email" :required="false" ></TextInput>
                    <TextInput type="text" label="Agency Phone" v-model="item.account.phone" :required="false" ></TextInput>
                </div>

                   

            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Create from '../../../mixins/Create'

export default {
    name: 'CreateService',
    mixins: [Create],
    data: () => ({
        back: '/customers',
        item: {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            password: '',
            username: '',
            accounts: [],
            accounts_roles: {},
            accounts_teams: {},
            account: {},
        },
        accountSearchParams: {
            'model': 'Account',
            'fields': ['id','name'],
            'action': '/search/',
        },
        action: '/customer/',
    }),
    computed: {
        formData() {
            return {
                'customer':this.item,
            }
        },
    },
    props: [],
    methods: {
        generatePw() {
            this.$set(this.item,'password',Math.random().toString(36).slice(-10))
        },
        accountDisplay(item) {
            return 'ID: ' + item.id + ' Name: ' + item.name;
        },
        accountSelected(evt) {
            let index = this.item.accounts.findIndex((account)=>{return account.id == evt.item.id})
            if(index < 0) this.item.accounts.push(evt.item);
        },
        accountRemoved(evt) {
            this.item.accounts.splice(evt.index,1);
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
