<template>
<div class="container pt-16 px-2 ">
    <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">


<!--  eligible for payout list-->
    <Payouts></Payouts>

<!--  referral list-->
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Referrals
        </h1>
    </div>

    <div class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/customers" customview="user_id" :columns="columns" :items="pages[page]"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
<!--        <Pagination @navigate="navigate" :count="count" :limit="limit" :page="page"></Pagination>-->
    </div>

<!--    <div class="flex">-->
<!--      <h1 class="text-red text-lg mb-6">-->
<!--        Top Campaigns-->
<!--      </h1>-->
<!--    </div>-->

<!--    <ul class="ml-8">-->
<!--      <li><b>Max Access:</b> <span v-if="this.campaignInfo['maxaccess'].paypal">{{this.campaignInfo['maxaccess'].paypal}}</span><span v-else>Unknown</span> ({{this.campaignInfo['maxaccess'].visits}} visits)</li>-->
<!--      <li><b>Toolbar:</b> <span v-if="this.campaignInfo['toolbar'].paypal">{{this.campaignInfo['toolbar'].paypal}}</span><span v-else>Unknown</span> ({{this.campaignInfo['toolbar'].visits}} visits)</li>-->
<!--      <li><b>Academy:</b> <span v-if="this.campaignInfo['academy'].paypal">{{this.campaignInfo['academy'].paypal}}</span><span v-else>Unknown</span> ({{this.campaignInfo['academy'].visits}} visits)</li>-->
<!--      <li><b>Default:</b> <span v-if="this.campaignInfo['default'].paypal">{{this.campaignInfo['default'].paypal}}</span><span v-else>Unknown</span> ({{this.campaignInfo['default'].visits}} visits)</li>-->
<!--    </ul>-->
</div>
</template>

<script>
import List from '../../../mixins/List'
import Payouts from './Payouts.vue';
export default {
    name: 'AffiliateList', 
    mixins: [List],
    data: () => ({
        limit: 10,
        page: 0,
        action: '/affiliates',
        referralInfo: [],
        campaignInfo: [],

        searchParams: {
            'model': 'Referrals',
            'fields': ['name', 'order_id','referrer_user_id','campaign_name','referred_user'],
        },
        columns:[
            {
              id:'id',
              label:'Referral ID',
            },
            {
              id:'name',
              label:'Name',
            },
            {
              id:'user_id',
              label:'Referred User',
            },
            {
              id:'affiliate_campaign',
              label:'Referrer Campaign',
            },
            {
                id:'order_id',
                label:'Order ID',
            },
            {
                id:'affiliate_id',
                label:'Affiliate',
                html_custom: 'linkReferrer',
            },
        ],
    }),
    computed: {
    },
    props: [],
    methods: {
        navigate(index) {
            if(index > -1 && index <= Math.floor(this.count/10)) {
                this.page = index
                this.getItems()
            }
        },
        GetCampaigns() {
            let that = this;
            this.$requests.get('/affiliate/getTopCampaigns',
            {
                success: (re) => {
                    that.campaignInfo = re.data.item;
                    return true
                },
            });
        },
        linkReferrer(item) {
          let html = ''
          html += `<a class="text-indigo-600 hover:text-indigo-900" href="/customers/${item.affiliate_user_id}/#Affiliates" >${item.affiliate_user_name}</a>`
          return html
        },
    },
    watch: {
    },
    created() {
        this.GetCampaigns();
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
      Payouts
    },
}
</script>
<style scoped>
</style>
