<template>

<!--  eligible for payout list-->
    <div>
      <div class="flex">
        <h1 class="text-red text-lg mb-6">
          Payouts
        </h1>
      </div>


      <div class="">
        <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" :columns="columns" :items="pages[page]" :triggerPopup="true"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
<!--        <Pagination @navigate="navigate" :count="count" :limit="limit" :page="page"></Pagination>-->
      </div>
    </div>

</template>

<script>
import List from '../../../mixins/List'
export default {
    name: 'Payouts',
    mixins: [List],
    data: () => ({
        limit: 10,
        page: 0,
        action: '/affiliates/eligible_for_payout',
        referralInfo: [],
        campaignInfo: [],

        searchParams: {
            'model': 'Referrals',
            'fields': ['name','payout_amount','paypal_email'],
        },
        columns:[
            {
              id:'name',
              label:'Name',
            },
            {
              id:'status',
              label:'Status',
            },
            {
                id:'payout_amount',
                label:'Payout Amount',
            },
            {
                id:'paypal_email',
                label:'Paypal Email',
            },
        ],
    }),
    computed: {
    },
    props: [],
    methods: {
        navigate(index) {
            if(index > -1 && index <= Math.floor(this.count/10)) {
                this.page = index
                this.getItems()
            }
        },
        GetCampaigns() {
            let that = this;
            this.$requests.get('/affiliate/getTopCampaigns',
            {
                success: (re) => {
                    that.campaignInfo = re.data.item;
                    return true
                },
            });
        },
    },
    watch: {
    },
    created() {
        this.GetCampaigns();
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
